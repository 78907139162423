<template>
  <div class="home">
    <div class="title-img"></div>
    <div class="start-img" @click="$router.push({ name: 'instruction' })"></div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      value: ''
    }
  }
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
.home {
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  background: url('../../assets/images/home-bg.png') no-repeat top center;
  background-size: 100% 100%;
}
.title-img {
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
  width: 351px;
  height: 84px;
  background: url('../../assets/images/title-img.png') no-repeat top center;
  background-size: 100%;
}
.start-img {
  position: absolute;
  top: 165px;
  left: 50%;
  transform: translateX(-50%);
  width: 210px;
  height: 53px;
  background: url('../../assets/images/start.png') no-repeat top center;
  background-size: 100%;
}
</style>
